import React, {Component} from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import NormalPageBlock from "../components/normal-page-block";
import Image from "../components/image"

class AboutDpad extends Component {
    render() {
        return (
            <Layout
                headerElement={<NormalPageBlock siteTitle="Our Mission and Vision" />}
                scrollStepInPx={50}
                delayInMs={2}
            >
            <SEO title="Mission and Vision" />
            <section id="section2">
                <div className="container">
                <div className="row">
                    <div className="col-lg-8" style={{paddingRight: '10%'}}>
                    <div className="heading m-b-40">
                        <h2>Our Mission</h2>
                    </div>
                    <p className="text-justify" style={{fontSize: 18}}>Recognizing that African Nova Scotians are a distinct founding people in Nova Scotia who have been a key part of the province’s culture and history since 1605, the DPAD Coalition’s mission is twofold: to build strength and health across African Nova Scotian communities, and to forge a renewed working relationship with government(s) that creates conditions for all African-descended people in Nova Scotia to thrive.<br /><br />
                        While acknowledging that much work remains to address the legacy of enslavement, segregation and generational effects of systemic anti-Black racism in Nova Scotia, we strive to call governments out of past attitudes and behaviours—doing to or for African Nova Scotian communities, instead of with—and into a meaningful engagement that respects people as agents in their own solutions. We seek a relationship where African Nova Scotians are engaged, included and listened to at all 
                        levels of policy- shaping and decision-making. We also seek to strengthen relationships amongst ourselves to equip communities and organizations to work collectively and holistically across sectors, in an accountable and mutually supportive way.
                    </p>
                    </div>
                    <div className="col-lg-4 col-md-12 text-light p-50 rounded" style={{backgroundColor: '#111'}}>
                    <div className="heading p-20 m-b-0" style={{marginTop: 48}}>
                        <h2 className="text-colored">Our Vision</h2>
                        <span className="text-light" style={{fontSize: 18}}>African Nova Scotian people and communities are recognized and supported as an integral part of Nova Scotia’s past, present and future.
                        </span>
                    </div>
                    <p className="p-20 m-b-0" style={{textAlign: 'center'}}>
                        <img src="https://ctrlcode-prod-images.s3.ca-central-1.amazonaws.com/ANSDPAD/dpad-logo.png" width="250" />
                    </p>
                    </div>
                </div>
                </div>
            </section>
            </Layout>
        );
    }
}

export default AboutDpad
